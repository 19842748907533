import { Pipe, PipeTransform } from '@angular/core';
import { AuthGroupQuery, AuthGroupService } from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { P48APIAuthorizationGroup } from '@lobos/common';

@Pipe({
  name: 'roleHumanReadable',
})
export class RoleHumanReadablePipe implements PipeTransform {
  constructor(
    private roleQuery: AuthGroupQuery<P48APIAuthorizationGroup>,
    private roleService: AuthGroupService<P48APIAuthorizationGroup>,
  ) {}

  public transform(roleId: number): Observable<string> {
    return of(roleId).pipe(
      switchMap((roleId: number) =>
        iif(
          () => this.roleQuery.getHasCache(),
          this.roleQuery.selectEntity(roleId),
          this.roleService.get().pipe(switchMap(() => this.roleQuery.selectEntity(roleId))),
        ),
      ),
      map((role: P48APIAuthorizationGroup | undefined) => role?.sRoleName || ''),
    );
  }
}
