import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { BreakpointConstant } from '../../constants/breakpoint.constant';
import ClickEvent = JQuery.ClickEvent;
import { Observable } from 'rxjs';
import { CmsFooter } from '../../services/cms/interfaces/cms-footer.interface';
import { CmsQuery } from '../../services/cms/cms.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  cmsFooter$: Observable<CmsFooter> = this.cmsQuery.cmsFooter$;
  cmsFooter: CmsFooter | undefined;
  protected readonly environment = environment;

  constructor(private cmsQuery: CmsQuery) {}

  /**
   * Copy of the design JS-delivery
   * Code is not optimized!
   */
  ngOnInit() {
    this.cmsFooter$.pipe(untilDestroyed(this)).subscribe((cmsFooter) => {
      this.cmsFooter = cmsFooter;

      if (cmsFooter) {
        if (typeof window === 'undefined') {
          return;
        }

        // setTimeout to make sure everything is loaded
        // eslint-disable-next-line angular/timeout-service
        setTimeout(() => {
          const $footerColumnTitle: JQuery = $('.footer-links-column-title');
          $footerColumnTitle.on('click', (event: ClickEvent<HTMLElement>) => {
            if (!window.matchMedia(`screen and (max-width: ${BreakpointConstant.MOBILE_LARGE}px)`).matches) {
              return;
            }

            const openClass = 'footer-links-column-title--open';
            const $target = $(event.currentTarget);
            const $content = $target.siblings('.footer-links-column-content');

            $target.toggleClass(openClass);
            $content.stop().slideToggle();
          });
        }, 100);
      }
    });
  }
}
