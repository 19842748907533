import { Inject, Injectable } from '@angular/core';
import { CATALOG_CONFIG, CatalogConfig, FacetedSearchAbstract, PageViewConfig, ParameterQuery } from '@lobos/library';
import { CategoryFacetedSearchResponse, Facet, SearchRequestInterface } from '@lobos/common';
import { finalize, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CategoryGroups, EhgFacetedSearchStore } from './ehg-faceted-search.store';
import { EhgFacetedSearchQuery } from './ehg-faceted-search.query';
import { FacetedSearchResponseInterface } from './interfaces/faceted-search-response.interface';
import { EhgProduct } from './model/ehg-product';

@Injectable({ providedIn: 'root' })
export class EhgFacetedSearchService extends FacetedSearchAbstract {
  constructor(
    private store: EhgFacetedSearchStore,
    private query: EhgFacetedSearchQuery,
    private parameterQuery: ParameterQuery,
    protected http: HttpClient,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {
    super();
    this.addSortMapping('title', [{ 'sTitle.keyword': { order: 'asc' } }]);
  }

  searchRootFacets(): Observable<Facet[]> {
    return this.parameterQuery.params$.pipe(
      switchMap((params) => {
        const searchRequestBody: SearchRequestInterface = {
          q: `/${params.sCatalogName.toLowerCase()}/`,
          facets: [],
          sort: [],
          intPageIndex: 0,
          intPageSize: 0,
          exclude: ['oArticles', 'oFeatureClass'],
        };

        return this.http.post<CategoryFacetedSearchResponse>(this.catalogConfig.apiUrl + '/search', searchRequestBody).pipe(
          map((response: CategoryFacetedSearchResponse) => {
            return response.facets;
          }),
        );
      }),
    );
  }

  searchByFiltersCached(
    navigationFilters: string[],
    queryFilters: any,
    viewConfig: PageViewConfig,
    exclude: string[] = [],
  ): Observable<CategoryGroups[]> {
    const key = this.generateFacetedSearchKey(navigationFilters, viewConfig, queryFilters);
    const cache = this.query.getEntity(key);

    if (cache) {
      this.store.setActive(key);
      return of(cache?.products || []);
    }

    this.store.setLoading(true);
    const searchRequestBody = {
      q: navigationFilters,
      facets: queryFilters.map((filter: any) => ({
        name: filter.name,
        value: filter.value,
      })),
      sort: this.getSortMapping(viewConfig.sortBy || this.getDefaultSort()) as any,
      intPageIndex: viewConfig.pageIndex,
      intPageSize: viewConfig.pageSize,
      exclude: exclude,
    };

    return this.http.post<FacetedSearchResponseInterface>(this.catalogConfig.apiUrl + '/search/hierarchy', searchRequestBody).pipe(
      map((response: FacetedSearchResponseInterface) => {
        this.store.add({
          ...response,
          id: key,
          products: [],
        });
        this.store.setActive(key);
        const productGroup = this.groupByPath(response.products, navigationFilters);
        this.store.updateActive({
          products: productGroup,
        });
        return productGroup;
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  updateProductTreeState(node: CategoryGroups): void {
    const state = { ...this.query.getActive()?.productTreeState };
    if (state[node.id]) {
      Object.keys(state).forEach((key) => {
        if (key.startsWith(node.id)) {
          delete state[key];
        }
      });

      delete state[node.id];
    } else {
      state[node.id] = node;
    }

    this.store.updateActive({
      productTreeState: state,
    });
  }

  generateFacetedSearchKey(navigation: string[], viewConfig: PageViewConfig, queryFilters: any): string {
    return `${JSON.stringify(navigation)}-${JSON.stringify(viewConfig)}-${JSON.stringify(queryFilters)}`;
  }

  groupByPath(objects: EhgProduct[], activeNavPaths: string[] = []) {
    const result: CategoryGroups[] = [];
    const state = { ...this.query.getActive()?.productTreeState };
    const newState: Record<string, CategoryGroups> = {};

    objects.forEach((product) => {
      const levels = product.listHierarchicalCategories.slice(1, -1);
      let currentLevel = result;

      levels.forEach((level, index) => {
        const existingNode = currentLevel.find((item) => item.title === level.sValue);

        if (existingNode) {
          // Node already exists, move to the next level
          if (index === levels.length - 1) {
            // Last level, add the object to the array
            existingNode.products.push(product);
            currentLevel = existingNode.children || [];
          } else {
            currentLevel = existingNode.children || [];
          }
        } else {
          // Node doesn't exist, create it
          const pathToNode = levels
            .map((l) => l.sValue)
            .slice(0, index + 1)
            .join('/');
          const newNode: CategoryGroups = {
            id: pathToNode,
            title: level.sValue,
            children: [],
            products: index === levels.length - 1 ? [product] : [],
            isOpen: false,
            sort: level.sort,
            path: product.sNavigationPath,
          };

          if (this.isNodeOpen(newNode, activeNavPaths) && newNode.products.length === 0) {
            newState[pathToNode] = newNode;
          }
          currentLevel.push(newNode);
          currentLevel = newNode.children;
        }
      });
    });

    if (!Object.keys(state).length) {
      this.store.updateActive({
        productTreeState: newState,
      });
    }

    return result;
  }

  isNodeOpen(node: CategoryGroups, activeNavPaths: string[]): boolean {
    return activeNavPaths.some((path) => node.path.startsWith(path));
  }
}
