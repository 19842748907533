<footer class="footer">
  <ng-container *ngIf="cmsFooter">
    <div class="footer-max-width">
      <div class="footer-information">
        <div class="footer-information-logo">
          <img src="/assets/images/logos/logo-white.svg" alt="EHG Stahl und Metall" />
        </div>

        <div class="footer-information-text">
          <div *ngIf="cmsFooter.address" [outerHTML]="cmsFooter.address"></div>

          <div class="footer-information-link">
            <span>T:</span><a href="tel:{{ cmsFooter.phone }}">{{ cmsFooter.phone }}</a>
          </div>

          <div class="footer-information-link">
            <span>E:</span><a href="mailto:{{ cmsFooter.email }}">{{ cmsFooter.email }}</a>
          </div>
        </div>
      </div>

      <div class="footer-links">
        <div class="footer-links-content">
          <div *ngFor="let navigationSection of cmsFooter.navigation" class="footer-links-column">
            <div class="footer-links-column-title">{{ navigationSection.title }}</div>

            <div class="footer-links-column-content">
              <a *ngFor="let navigationElement of navigationSection.items" [href]="environment.cms.host + navigationElement.path">{{
                navigationElement.title
              }}</a>
            </div>
          </div>
        </div>

        <!-- TODO secondary navigation -->
        <div class="footer-links-lower">
          <div class="footer-links-lower-items">
            <ng-container *ngIf="cmsFooter['secondary-navigation'].length">
              <a href="#">Datenschutz</a>
            </ng-container>
          </div>

          <div class="footer-links-lower-socials">
            <a *ngFor="let social of cmsFooter['social-media']" [href]="social.url">
              <i [ngClass]="social['icon-class']"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</footer>
