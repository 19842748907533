<a
  [routerLink]="url || 'checkout/cart/' + (activeCartId$ | async) | localizeUrlPath"
  [ngClass]="classes"
  *ngIf="!disabled; else disabledCart"
>
  <i class="{{ isFavoriteLink ? 'icon-star' : 'icon-shop-cart' }}"></i>

  <div class="navigation-cart-counter" *ngIf="itemCount !== undefined">{{ itemCount }}</div>
</a>
<ng-template #disabledCart>
  <i class="{{ isFavoriteLink ? 'icon-star' : 'icon-shop-cart' }}"></i>
</ng-template>
