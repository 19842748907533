<ng-container *transloco="let t">
  <div class="text-input" [class.text-input--button]="hasButton" [class.text-input--error]="hasError" [ngClass]="classes">
    <p *ngIf="label" class="text-input-label">
      {{ label }}
      <ng-container *ngIf="required">*</ng-container>
    </p>

    <div class="text-input-container">
      <ng-container *ngIf="form" [formGroup]="form">
        <input
          [matAutocomplete]="autoComplete"
          [matAutocompleteDisabled]="!autoComplete"
          [type]="type"
          [name]="name"
          [readonly]="readonly"
          [placeholder]="placeholder"
          [required]="required"
          [formControlName]="name"
          (focusin)="this.focusInEvent.emit($event)"
          (focusout)="this.focusOutEvent.emit($event)"
          [class.disabled]="disabled"
        />
      </ng-container>

      <input
        *ngIf="!form"
        [matAutocomplete]="autoComplete"
        [matAutocompleteDisabled]="!autoComplete"
        [readonly]="readonly"
        [type]="type"
        [name]="name"
        [placeholder]="placeholder"
        [required]="required"
        [(ngModel)]="value"
        (ngModelChange)="valueChange.emit(value)"
        (click)="inputClicked.emit(true)"
        [class.disabled]="disabled"
      />

      <button *ngIf="hasButton" (click)="this.buttonClicked.emit(value)" [class.navigation-search-submit]="isSearchInput">
        <i [ngClass]="buttonIcon"></i>
      </button>
      <button *ngIf="isSearchInput" (click)="clearInput()" class="navigation-search-clear js-search-clear" type="reset">
        <i class="icon-close"></i>
      </button>
    </div>

    <p *ngIf="hasError && errorText" class="text-input-error-text">{{ errorText }}</p>
    <button *ngIf="isSearchInput" (click)="clearInput()" class="navigation-search-clear-mobile js-search-clear" type="reset">
      {{ t("core.menu.search.cancel") }}
    </button>
  </div>
</ng-container>
