import { Pipe, PipeTransform } from '@angular/core';
import { PickListEntry } from '@lobos/library';

@Pipe({
  name: 'pickListOptionsList',
})
export class PicklistOptionsListPipe implements PipeTransform {
  public transform(entries: PickListEntry[] | null): { key: string; value: string }[] {
    const normalizedEntries: { key: string; value: string }[] = [];
    if (!entries?.length) {
      return normalizedEntries;
    }

    for (const entry of entries) {
      normalizedEntries.push({
        key: entry.sName,
        value: entry.sName,
      });
    }

    return normalizedEntries;
  }
}
