import { Pipe, PipeTransform } from '@angular/core';
import { MaterialNumberHelperService } from '../../services/catalog/material-number.helper.service';
import { EhgProduct } from '../../services/catalog/model/ehg-product';

@Pipe({
  name: 'sortLastLevelByMaterialNumber',
})
export class SortLastLevelProductsByMaterialNumberPipe implements PipeTransform {
  constructor(private materialNumberHelper: MaterialNumberHelperService) {}

  public transform(products: EhgProduct[]): EhgProduct[] {
    return products.slice().sort((a, b) => {
      if (a.sPL1MaterialNumbers && a.sPL1MaterialNumbers.length) {
        if (b.sPL1MaterialNumbers && b.sPL1MaterialNumbers.length) {
          return this.materialNumberHelper.convertToStringArray(a)[0].localeCompare(this.materialNumberHelper.convertToStringArray(b)[0]);
        }
        return -1000;
      }
      return 1000;
    });
  }
}
