import { NgModule } from '@angular/core';
import { AddressSummaryPipe } from './address-summary/address-summary.pipe';
import { ContactListPipe } from './contact-list/contact-list.pipe';
import { CountryListPipe } from './country-list/country-list.pipe';
import { GetErrorPipe } from './get-error/get-error.pipe';
import { LanguageHumanReadablePipe } from './language-human-readable/language-human-readable.pipe';
import { LanguageListPipe } from './language-list/language-list.pipe';
import { RoleHumanReadablePipe } from './role-human-readable/role-human-readable.pipe';
import { RoleListPipe } from './role-list/role-list.pipe';
import { ShippingAddressListPipe } from './shipping-address-list/shipping-address-list.pipe';
import { ShippingTypeListPipe } from './shipping-type-list/shipping-type-list.pipe';
import { CartItemIsChildPipe } from './cart-item-is-child/cart-item-is-child.pipe';
import { CartItemIsMasterPipe } from './cart-item-is-master/cart-item-is-master.pipe';
import { UrlPathPipe } from './url-path/url-path.pipe';
import { SteelProcessingItemCheckedPipe } from './steel-processing-item-checked/steel-processing-item-checked.pipe';
import { UrlFilterParamsPipe } from './url-filter-params/url-filter-params.pipe';
import { CartItemIsChildProcessedPipe } from './cart-item-is-child-processed/cart-item-is-child-processed.pipe';
import { CartItemIsNotProcessedPipe } from './cart-item-is-not-processed/cart-item-is-not-processed.pipe';
import { ShapeIconClassPipe } from './shape-icon-class/shape-icon-class.pipe';
import { SalesRepFieldListPipe } from './sales-rep-field-list/sales-rep-field-list.pipe';
import { GetMaterialNumberPipe } from './get-material-number/get-material-number.pipe';
import { SortProductsByMaterialNumberPipe } from './sort-products-by-material-number/sort-products-by-material-number.pipe';
import { FeatureArticleDetailPipe } from './feature-article-detail/feature-article-detail.pipe';
import { SortLastLevelProductsByMaterialNumberPipe } from './sort-last-level-products-by-material-number/sort-last-level-products-by-material-number.pipe';
import { PicklistOptionsListPipe } from './picklist-options-list/picklist-options-list.pipe';

const pipes: any[] = [
  GetErrorPipe,
  AddressSummaryPipe,
  ShippingAddressListPipe,
  ShippingTypeListPipe,
  CountryListPipe,
  LanguageListPipe,
  RoleListPipe,
  ContactListPipe,
  LanguageHumanReadablePipe,
  RoleHumanReadablePipe,
  CartItemIsChildPipe,
  CartItemIsMasterPipe,
  UrlPathPipe,
  SteelProcessingItemCheckedPipe,
  UrlFilterParamsPipe,
  CartItemIsChildProcessedPipe,
  CartItemIsNotProcessedPipe,
  ShapeIconClassPipe,
  SalesRepFieldListPipe,
  GetMaterialNumberPipe,
  SortProductsByMaterialNumberPipe,
  SortLastLevelProductsByMaterialNumberPipe,
  FeatureArticleDetailPipe,
  PicklistOptionsListPipe,
];

@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
})
export class PipesModule {}
