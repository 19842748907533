<div *transloco="let t; scope: 'shared'" class="overlay-container">
  <p mat-dialog-title>{{ t(data.title) }}</p>
  <div mat-dialog-content>{{ t(data.text) }}</div>
  <mat-dialog-actions align="end">
    <app-button
      [style]="'secondary'"
      [text]="t('shared.confirmation-dialog.cancel-button-text')"
      mat-dialog-close
      [classes]="'dialog-button'"
    ></app-button>
    <app-button
      [style]="'primary'"
      [text]="t(data.confirmationButtonText)"
      (click)="onConfirmClick()"
      [classes]="'dialog-button'"
    ></app-button>
  </mat-dialog-actions>
</div>
