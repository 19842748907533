import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  public style: 'primary' | 'secondary' = 'primary';

  @Input()
  public url: string = '';

  @Input()
  public type: string = 'button';

  @Input()
  public classes: string = '';

  @Input()
  public text: string = '';

  @Input()
  public isIconRight: boolean = false;

  @Input()
  public icon: string = '';

  @Input()
  public disabled: boolean = false;

  @Input()
  public loading: boolean = false;
}
