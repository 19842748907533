import { Pipe, PipeTransform } from '@angular/core';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { EhgCartItem } from '../../interfaces/ehg-cart-item.interface';

@Pipe({
  name: 'cartItemIsMaster',
})
export class CartItemIsMasterPipe implements PipeTransform {
  transform<T extends EhgCartItem>(items: T[]): T[] {
    return items ? items.filter((item: T) => ArticleHelperService.itemIsMaster(item)) : [];
  }
}
