import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Product } from '@lobos/common';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-product-image',
  templateUrl: 'product-image.component.html',
  styleUrls: ['product-image.component.scss'],
})
export class ProductImageComponent {
  @Output()
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  imageType: string = 'product-detail';
  imageUrl: string = '';

  private _product!: Product;

  get product() {
    return this._product;
  }

  @Input() set product(value: Product) {
    this._product = value;
    if (this._product && this._product.oResources) {
      const res = this._product.oResources.find((r) => {
        if (this.imageType === 'product-detail') {
          return r.shtResourceGroupIDInternal === 102;
        } else if (this.imageType === 'figure-image') {
          return r.shtResourceGroupIDInternal === 101;
        }
        return false;
      });

      this.imageUrl = res ? `${environment.imagePath}/${res.sResourcePath.replace(environment.enventaImageSource, '')}` : '';
    }
  }
}
