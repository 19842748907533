import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Article, HierarchicalCategory } from '@lobos/common';

@Component({
  selector: 'app-article-list-item',
  templateUrl: './article-list-item.component.html',
  styleUrls: ['./article-list-item.component.scss'],
})
export class ArticleListItemComponent implements OnInit {
  @Input() article!: Article;

  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  hierarchies: HierarchicalCategory[] = [];
  imageIsHidden: boolean = false;

  ngOnInit() {
    this.hierarchies = this.article.oProductInfo[0].listHierarchicalCategories as HierarchicalCategory[];
  }
}
