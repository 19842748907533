import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-text',
  templateUrl: './button-text.component.html',
  styleUrls: ['./button-text.component.scss'],
})
export class ButtonTextComponent {
  @Input()
  public url: string = '';

  @Input()
  public type: string = 'button';

  @Input()
  public classes: string = '';

  @Input()
  public text: string = '';

  @Input()
  public isIconRight: boolean = false;

  @Input()
  public icon: string = '';
}
