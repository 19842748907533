import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
})
export class ButtonIconComponent {
  @Input()
  public url: string = '';

  @Input()
  public classes: string = '';

  @Input()
  public disabled: boolean = false;

  @Input()
  public icon: string = '';

  @Input()
  public tooltip: string = '';

  @Input()
  public queryParams?: Params;

  @Input()
  public loading: boolean = false;

  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();
}
