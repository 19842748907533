<a *ngIf="url" [routerLink]="url | localizeUrlPath" class="button-text" [ngClass]="classes">
  <i *ngIf="!isIconRight && icon" [ngClass]="icon"></i>

  <span *ngIf="text">{{ text }}</span>

  <i *ngIf="isIconRight && icon" [ngClass]="icon"></i>
</a>

<button *ngIf="!url" [type]="type" class="button-text" [ngClass]="classes">
  <i *ngIf="!isIconRight && icon" [ngClass]="icon"></i>

  <span *ngIf="text">{{ text }}</span>

  <i *ngIf="isIconRight && icon" [ngClass]="icon"></i>
</button>
