<div class="select-box" [ngClass]="classes">
  <p *ngIf="label" class="select-box-label" [ngClass]="labelClasses">{{ label }}<ng-container *ngIf="required">*</ng-container></p>
  <div class="select-box-select" [class.select-box-select--error]="hasError">
    <ng-container *ngIf="form" [formGroup]="form">
      <select
        [required]="required"
        [name]="name"
        [formControlName]="name"
        [attr.disabled]="disabled ? '' : null"
        [class.disabled]="disabled"
      >
        <option *ngIf="placeholder" selected="selected" [ngValue]="''">
          {{ placeholder }}
        </option>

        <option *ngFor="let option of options" [ngValue]="option.key" [disabled]="option.disabled">
          {{ option.value }}
        </option>
      </select>
    </ng-container>

    <select
      *ngIf="!form"
      [required]="required"
      [name]="name"
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit(value)"
      [attr.disabled]="disabled ? '' : null"
      [class.disabled]="disabled"
    >
      <option *ngIf="placeholder" selected="selected" [ngValue]="''">
        {{ placeholder }}
      </option>

      <option *ngFor="let option of options" [ngValue]="option.key" [disabled]="option.disabled">
        {{ option.value }}
      </option>
    </select>
  </div>

  <div *ngIf="hasError && errorText" class="select-box-error-message">
    <div class="select-box-error-icon">
      <i class="icon-info"></i>
      <span *ngIf="tooltip" class="select-box-error-tooltip">{{ tooltip }}</span>
    </div>
    <span>{{ errorText }}</span>
  </div>
</div>
