import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-fancy-input',
  templateUrl: './fancy-text-input.component.html',
  styleUrls: ['./fancy-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FancyTextInputComponent implements OnInit {
  @Input() public label: string = '';

  @Input() public name: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input() public unit: string = '';

  @Input() public min?: number | string = undefined;

  @Input() public maxlength: any;

  @Input() public disabled: boolean = false;

  @Input() public containerText: string = '';

  @Input()
  public valueChangeDebouncing: number = 500;
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
  public valueChangeDebouncer: Subject<string> = new Subject<string>();

  public _value: string = '';

  get value(): string {
    return this._value;
  }

  @Input() set value(val: string) {
    console.log('setting value: ' + val);
    this._value = val;
  }

  ngOnInit() {
    this.valueChangeDebouncer.pipe(debounceTime(this.valueChangeDebouncing)).subscribe((input: string) => this.valueChange.emit(input));
  }
}
