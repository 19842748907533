<div class="datepicker">
  <p class="datepicker-label" [class.text-input-error-text]="hasError">
    {{ label }}
    <ng-container *ngIf="required">*</ng-container>
  </p>
  <div class="text-input text-input--button" [class.text-input--error]="hasError">
    <div class="text-input-container">
      <ng-container *ngIf="form" [formGroup]="form">
        <input
          [maxlength]="maxlength"
          type="text"
          [name]="name"
          [attr.min]="min"
          [placeholder]="placeholder"
          [required]="required"
          [formControlName]="name"
          [class.border-error]="hasError"
          tabindex="0"
          matInput
          [matDatepicker]="$any(picker)"
          (click)="picker.open()"
        />
        <button (click)="picker.open()" type="button">
          <i class="icon-calendar"></i>
        </button>
      </ng-container>

      <ng-container *ngIf="!form">
        <input
          [maxlength]="maxlength"
          type="text"
          [name]="name"
          [attr.min]="min"
          [placeholder]="placeholder"
          [required]="required"
          [(ngModel)]="value"
          (ngModelChange)="valueChangeDebouncer.next(value)"
          [class.border-error]="hasError"
          tabindex="0"
          matInput
          [matDatepicker]="$any(picker)"
          (click)="picker.open()"
        />
        <button (click)="picker.open()">
          <i class="icon-calendar"></i>
        </button>
      </ng-container>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <p *ngIf="hasError && errorText" class="text-input-error-text">{{ errorText }}</p>
  </div>
</div>
