<ng-container *transloco="let t">
  <app-text-input
    name="q"
    [placeholder]="t('core.menu.search.placeholder')"
    [hasButton]="true"
    [isSearchInput]="true"
    buttonIcon="icon-search"
    [(value)]="searchTerm"
    (valueChange)="search()"
    (inputClicked)="searchIsFocused.emit(true)"
    (clearButtonClicked)="clearSearch()"
  ></app-text-input>
  <div>
    <div class="live-search js-live-search mobile-open">
      <ng-container *ngIf="articles?.length || cmsSearchResult?.hits?.items?.length; else noSearchResult">
        <h4 class="live-search-result-info">
          ({{ totalResults }}) {{ t("core.menu.search.total-search-results") }} "<strong>{{ searchTerm }}</strong
          >"
        </h4>

        <mat-tab-group mat-stretch-tabs>
          <mat-tab label="{{ t('core.menu.search.shop.title', { resultNumber: shopSearchResult?.articleTotal }) }}">
            <ng-container *ngIf="articles && articles.length; else noSearchResult">
              <div class="live-search-header">
                <p>{{ t("core.menu.search.shop.articles") }}</p>
                <div class="live-search-header-right">
                  <p>{{ t("core.menu.search.shop.found-shop-results") }}: {{ articles.length }}/{{ shopSearchResult?.articleTotal }}</p>
                  <a
                    [routerLink]="['search' | localizeUrlPath]"
                    [queryParams]="{ q: searchTerm }"
                    (click)="closeOnClick($event)"
                    class="live-search-header-right-link"
                    >{{ t("core.menu.search.shop.show-all-shop-results") }}
                    <i class="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
              <app-article-list-item
                *ngFor="let article of articles | slice : 0 : 4"
                [article]="article"
                (itemClicked)="closeOnClick($event)"
              >
              </app-article-list-item>
              <a
                [routerLink]="['search' | localizeUrlPath]"
                [queryParams]="{ q: searchTerm }"
                (click)="closeOnClick($event)"
                class="live-search-all-link-mobile"
                >{{ t("core.menu.search.shop.show-all-shop-results") }} <i class="icon-arrow-right"></i
              ></a>
            </ng-container>
          </mat-tab>

          <!--          <mat-tab label="{{ t('core.menu.search.content.title', { resultNumber: cmsSearchResult?.hits?.total || 0 }) }}">-->
          <!--            <ng-container *ngIf="cmsSearchResult?.hits?.items?.length; else noSearchResult">-->
          <!--              <div class="live-search-header">-->
          <!--                <p>{{ t("core.menu.search.content.results") }}</p>-->
          <!--                <div class="live-search-header-right">-->
          <!--                  <p>-->
          <!--                    {{ t("core.menu.search.content.found-content-results") }}-->
          <!--                    <span [innerText]="totalCmsResults >= 4 ? '4/' + totalCmsResults : totalCmsResults + '/' + totalCmsResults"></span>-->
          <!--                  </p>-->
          <!--                  <a [href]="environment.cms.host + '/' + currentLanguage + '/search?q=' + searchTerm" class="live-search-header-right-link"-->
          <!--                    >{{ t("core.menu.search.content.show-all-content-results") }} <i class="icon-arrow-right"></i-->
          <!--                  ></a>-->
          <!--                </div>-->
          <!--              </div>-->

          <!--              <a-->
          <!--                *ngFor="let contentItem of cmsSearchResult?.hits?.items | slice : 0 : 4"-->
          <!--                [href]="environment.cms.host + contentItem.path"-->
          <!--                class="live-search-item"-->
          <!--              >-->
          <!--                <div class="live-search-item-container">-->
          <!--                  <p class="live-search-item-title">{{ contentItem.title }}</p>-->
          <!--                  <div class="live-search-item-description">-->
          <!--                    <p>{{ contentItem.description }}</p>-->
          <!--                  </div>-->
          <!--                  <p class="live-search-item-link">{{ environment.cms.host + contentItem.path }}</p>-->
          <!--                </div>-->
          <!--              </a>-->
          <!--              <a [href]="environment.cms.host + '/' + currentLanguage + '/search?q=' + searchTerm" class="live-search-all-link-mobile"-->
          <!--                >{{ t("core.menu.search.content.show-all-content-results") }} <i class="icon-arrow-right"></i-->
          <!--              ></a>-->
          <!--            </ng-container>-->
          <!--          </mat-tab>-->
        </mat-tab-group>
      </ng-container>

      <ng-template #noSearchResult>
        <div class="search-no-results">
          <div class="search-no-results-avatar">
            <i class="icon-search"></i>
          </div>
          <div class="search-no-results-info">
            <h4 class="search-no-results-info-title">{{ t("core.menu.search.no-result.title") }}</h4>
            <p class="search-no-results-info-description">
              {{ t("core.menu.search.no-result.description") }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
