import { Pipe, PipeTransform } from '@angular/core';
import { CategoryGroups } from '../../services/catalog/ehg-faceted-search.store';
import { MaterialNumberHelperService } from '../../services/catalog/material-number.helper.service';

@Pipe({
  name: 'sortByMaterialNumber',
})
export class SortProductsByMaterialNumberPipe implements PipeTransform {
  constructor(private materialNumberHelper: MaterialNumberHelperService) {}

  public transform(categoryGroup: CategoryGroups[]): CategoryGroups[] {
    if (categoryGroup.some((child) => child.products.length)) {
      return categoryGroup.slice().sort((a, b) => {
        if (a.products.some((item) => item.sPL1MaterialNumbers.length)) {
          if (b.products.some((item) => item.sPL1MaterialNumbers.length)) {
            return this.materialNumberHelper
              .convertToStringArray(a.products)[0]
              .localeCompare(this.materialNumberHelper.convertToStringArray(b.products)[0]);
          }
          return -1000;
        }
        return 1000;
      });
    }
    return categoryGroup.slice().sort((a, b) => a.sort - b.sort);
  }
}
