import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Catch } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent {
  public availableLanguages = environment.availableLang.map((lang: string) => ({ key: lang, value: lang.toUpperCase() }));
  public currentLanguage = this.transloco.getActiveLang();

  constructor(private authService: AuthService, private transloco: TranslocoService, private router: Router) {}

  @Catch({ label: 'LanguageSelectComponent.changeLanguage()' })
  public changeLanguage(): Promise<boolean> {
    return this.router.navigate([this.currentLanguage]);
  }
}
