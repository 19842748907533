import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NavigationQuery } from '../../services/catalog/navigation/navigation.query';
import { Observable } from 'rxjs';
import { Navigation } from '../../services/catalog/navigation/navigation.store';

@UntilDestroy()
@Component({
  selector: 'app-navigation-item-menu',
  templateUrl: './navigation-item-menu.component.html',
  styleUrls: ['./navigation-item-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemMenuComponent {
  @Output()
  navItemClicked = new EventEmitter<boolean>();

  @Output()
  backButtonClicked = new EventEmitter<boolean>();

  navigation$: Observable<Navigation[]> = this.navigationQuery.navigation$;

  constructor(private navigationQuery: NavigationQuery) {}
}
