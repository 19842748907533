<ng-container *transloco="let t; scope: 'catalog'">
  <div class="amount-counter">
    <p class="amount-counter-label" *ngIf="showLabel">{{ t("catalog.article.detail.configuration.amount") }}</p>
    <div class="amount-counter-container">
      <button class="amount-counter-container-button" (click)="quantityMinus()"><i class="icon-minus"></i></button>
      <form [formGroup]="amountForm">
        <div class="text-input">
          <input type="number" pattern="\d*" class="amount-counter-container-number" formControlName="quantity" />
        </div>
      </form>
      <button class="amount-counter-container-button" (click)="quantityPlus()">
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
  <p *ngIf="amountForm.invalid" class="text-input-error-text">
    {{ t("catalog.article.detail.configuration.errors.amount") }}
  </p>
</ng-container>
