import { Injectable } from '@angular/core';
import { ElasticSearchService, HookEvent, HookHandlerAbstract, LanguageChangedHook } from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = LanguageChangedHook;

@Injectable({
  providedIn: 'root',
})
export class LanguageChangedChangeElasticSearchIndexHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private elasticSearch: ElasticSearchService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    return super.after(event, result).pipe(tap((lang: string) => this.elasticSearch.setIndexLanguage(lang)));
  }
}
