<header class="simple-header">
  <div class="simple-header-top">
    <a *ngIf="backUrl" [routerLink]="backUrl | localizeUrlPath" class="simple-header-back">
      <i class="simple-header-arrow icon-arrow-left"></i>
      {{ backTitle }}
    </a>
  </div>
  <div class="simple-header-bottom">
    <h1 class="simple-header-title">{{ title }}</h1>
  </div>
</header>
