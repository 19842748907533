import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { AuthService, CORE_CONFIG, CoreConfig } from '@lobos/library';
import { EhgUser } from '../interfaces/ehg-user.interface';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class EhgBaseInterceptor implements HttpInterceptor {
  private urlsWithCustomerSpecificBranchKey: string[] = [
    '/carts',
    '/so-carts',
    '/stock-availability',
    'sales-orders',
    'sales-offers',
    'backlog',
    'factory-attestation',
    'sales-documents',
  ];

  constructor(@Inject(CORE_CONFIG) private coreConfig: CoreConfig, private authService: AuthService<EhgUser>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (['auth/me', 'auth/refresh-token', 'auth/hasCookie'].some((path) => request.url.includes(path))) {
      return next.handle(request);
    }

    return this.authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => iif(() => isLoggedIn, this.authService.authUser$, of(undefined))),
      first(),
      switchMap((user: EhgUser | undefined) => {
        request = request.clone({
          headers: request.headers.set(
            'businessUnit',
            user?.oCustomer?.sPL1BranchKeyWebshop && this.customerSpecificBranchKeyRequired(request.url)
              ? user.oCustomer.sPL1BranchKeyWebshop
              : this.coreConfig.branchKey,
          ),
        });
        return next.handle(request);
      }),
    );
  }

  private customerSpecificBranchKeyRequired(url: string) {
    return this.urlsWithCustomerSpecificBranchKey.some((bsup) => url.includes(bsup));
  }
}
