import { Pipe, PipeTransform } from '@angular/core';
import { Contact, User } from '@lobos/common';

@Pipe({
  name: 'contactList',
})
export class ContactListPipe implements PipeTransform {
  public transform(contacts: Contact[] | null, users: User[]): { key: string; value: string }[] {
    const normalizedContacts: { key: string; value: string; disabled?: boolean }[] = [];
    if (!contacts?.length) {
      return normalizedContacts;
    }

    for (const contact of contacts) {
      normalizedContacts.push({
        key: contact.lngContactId.toString(),
        value: `${contact.sFirstName} ${contact.sLastName}`,
        disabled: users.some((u) => u.lngContactID === contact.lngContactId),
      });
    }
    normalizedContacts.sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? (b.disabled ? 0 : 1) : -1));

    return normalizedContacts;
  }
}
