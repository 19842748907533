import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Navigation, NavigationStore } from './navigation.store';
import { finalize } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private store: NavigationStore, private http: HttpClient) {}

  getNavigation() {
    this.store.setLoading(true);
    return this.http.get<Navigation[]>('api/search/navigation').pipe(
      tap((navigation) => {
        this.store.update({ navigation });
      }),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }
}
