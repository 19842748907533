import { Inject, Injectable } from '@angular/core';
import { PaginationResponseInterface, SalesDocument, SalesOrder } from '@lobos/common';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { SALES_DOCUMENT_CONFIG, SalesDocumentQuery, SalesDocumentService, SalesDocumentStore } from '@lobos/library';
import { SalesDocumentConfig } from '@lobos/library/lib/sales-document/config/sales-document.config';

@Injectable({
  providedIn: 'root',
})
export class EhgSalesDocumentService<T extends SalesDocument = SalesDocument> extends SalesDocumentService<T> {
  private docInfoBaseURL = 'api/sales-documents-info';
  constructor(
    _salesDocumentQuery: SalesDocumentQuery<T>,
    _store: SalesDocumentStore,
    private _http: HttpClient,
    @Inject(SALES_DOCUMENT_CONFIG) public _salesDocumentConfig: SalesDocumentConfig,
  ) {
    super(_salesDocumentQuery, _store, _http, _salesDocumentConfig);
  }

  downloadDocument(salesOrder: SalesOrder, documentType: string = 'OC'): Observable<any> {
    const acceptHeaders = new HttpHeaders();
    acceptHeaders.append('Accept', '*/*');
    acceptHeaders.append('Accept-Encoding', 'gzip, deflate, br');
    acceptHeaders.append('Connection', 'keep-alive');
    return this._http
      .get(`api/sales-documents?sDocumentType=${documentType}&lngDocumentID=${salesOrder.lngOrderID}&sOrderType=${salesOrder.sOrderType}`, {
        headers: acceptHeaders,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        take(1),
        tap((res) => {
          const contentType = res.headers.get('content-type') || '';
          this.ehgSaveFileAs(res.body, `${salesOrder.lngOrderID}.pdf`, contentType);
        }),
      );
  }

  ehgSaveFileAs(file: any, fileName: string, type: string) {
    const blob = new Blob([file], { type });
    saveAs(blob, fileName);
  }

  getDocumentsBySalesOrder(salesOrderId: number): Observable<T[]> {
    return this._http
      .get<PaginationResponseInterface<T>>(`${this.docInfoBaseURL}/sales-order/${salesOrderId}`)
      .pipe(map((result: PaginationResponseInterface<T>) => result.data));
  }
}
