import { Component, Input, OnInit } from '@angular/core';
import { ID } from '@datorama/akita';
import { CartHeaderQuery, CartItemQuery, FavoriteItemQuery, FavoriteListQuery } from '@lobos/library';
import { FavoriteItem, SteelCartItem } from '@lobos/common';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-cart',
  templateUrl: './navigation-cart.component.html',
  styleUrls: ['./navigation-cart.component.scss'],
})
export class NavigationCartComponent implements OnInit {
  @Input()
  public url: string = '';

  @Input()
  public classes: string = '';

  @Input()
  public disabled: boolean = false;

  @Input()
  public isFavoriteLink: boolean = false;

  public activeCartId$: Observable<ID | undefined | null> = this.cartHeaderQuery.selectActiveId();

  public itemCount$: Observable<number> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | undefined | null) => !!id),
    map((id: ID | undefined | null) => id!),
    switchMap((id: ID) => this.cartItemQuery.selectCartItems(id)),
    map((items: SteelCartItem[]) => items.filter((item) => !item.shtProcessedItem && item.oArticle?.oProductInfo?.length > 0).length),
  );

  public activeFavoriteItems$: Observable<FavoriteItem[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
  );

  public itemCount: number | undefined;

  constructor(
    private cartHeaderQuery: CartHeaderQuery,
    private cartItemQuery: CartItemQuery<SteelCartItem>,
    private favoriteListQuery: FavoriteListQuery,
    private favoriteItemQuery: FavoriteItemQuery,
  ) {}

  ngOnInit() {
    if (this.isFavoriteLink) {
      this.activeFavoriteItems$.subscribe((favoriteItems) => (this.itemCount = favoriteItems.length));
    } else {
      this.itemCount$.subscribe((itemCount) => (this.itemCount = itemCount));
    }
  }
}
