import { Injectable } from '@angular/core';
import { CartHeader, SteelCartItem, User } from '@lobos/common';
import {
  AuthChangedHook,
  CartService,
  ErrorService,
  HookEvent,
  HookHandlerAbstract,
  HookPriority,
  SteelCreateCartItemInterface,
  switchTap,
} from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

type type = AuthChangedHook;

/**
 * This hook converts locally saved carts to a real (user-) cart,
 * as soon as the user logs in.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizedConvertLocalCartHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(
    private errorService: ErrorService,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override priority(): number {
    return HookPriority.HIGH;
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(
      switchTap<User>((user?: User) =>
        iif(
          () => !user,
          of(user),
          this.cartService.convertLocalToRemote(user!).pipe(
            catchError((error: Error) =>
              of(
                this.errorService.add({
                  label: 'AuthorizedConvertLocalCart.after()',
                  ...error,
                  error,
                }),
              ),
            ),
            first(),
          ),
        ),
      ),
    );
  }
}
