import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StringHelper, UrlHelperService, UrlPathHelperService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationInterface } from '../../interfaces/navigation.interface';
import { sortBy } from 'lodash';
import { ID } from '@datorama/akita';
import { Catalog, Facet, Feature, Parameter } from '@lobos/common';
import { EhgTranslatedFeature } from '../../interfaces/ehg-translated-feature.interface';
import { EhgTranslatedFacet } from '../../interfaces/ehg-translated-facet.interface';
import { EhgTranslatedFacetOption } from '../../interfaces/ehg-translated-facet-option.interface';

@Injectable({
  providedIn: 'root',
})
export class CatalogHelperService {
  public static emptyCharacteristicValue = '_thisValueNeverExists_';
  private translatedFeatureIDs = ['10101'];
  private translationStore: { [key: string]: string } = {};
  private fallbackValue = '___';

  constructor(private translocoService: TranslocoService, private urlHelper: UrlHelperService, private location: Location) {
    this.translocoService
      .selectTranslate('shared.feature.fallback', undefined, { scope: 'catalog' })
      .subscribe((translatedFallback) => (this.fallbackValue = translatedFallback));
  }

  public localizedUrlPath(_: Parameter, path: string, language: string): string {
    const slashAfterCatalogNameIndex = path.indexOf('/', 2);
    const pathWithoutCatalogName = path.slice(slashAfterCatalogNameIndex + 1);
    return StringHelper.rtrim(`/${language}/${pathWithoutCatalogName}`, '/');
  }

  private getGroupsForParent(
    parentId: ID,
    parents: {
      [key: ID]: Catalog[];
    },
  ): NavigationInterface[] {
    const group: Catalog[] = parents[parentId] || [];

    return this.mapGroupToNav(sortBy(group, 'lngItemId'), parents);
  }

  private mapGroupToNav(
    catalog: Catalog[],
    parents: {
      [key: ID]: Catalog[];
    },
  ): NavigationInterface[] {
    return catalog.map((group: Catalog) => ({
      title: group.sTitle,
      url: this.getCatalogUrlPath(group),
      subNavigation: this.getGroupsForParent(group.lngGroup as number, parents),
    }));
  }

  private getCatalogUrlPath(category: Catalog) {
    return UrlPathHelperService.getUrlPath(
      typeof category?.sUrlPath === 'string' ? category.sUrlPath : category.sUrlPath[0],
      this.translocoService.getActiveLang(),
    );
  }

  public getCatalogPathByLocation(path: string | string[]): string {
    if (Array.isArray(path)) {
      const urlLocation = this.location.path().split('?')[0].slice(4);
      return path.find((item) => item.includes(urlLocation)) || path[0];
    }

    return path;
  }

  public translateFeature(feature: Feature): EhgTranslatedFeature {
    let translatedValue = '';
    if (this.translatedFeatureIDs.includes(`${feature.lngFeatureID}`)) {
      const translationKey = `catalog.shared.feature.${feature.lngFeatureID}.values.${feature.sValue}`;
      if (this.translationStore[translationKey] || this.translationStore[translationKey] === '')
        translatedValue = this.translationStore[translationKey];
      else {
        this.translocoService.selectTranslate(translationKey).subscribe((res) => {
          if (res === translationKey) translatedValue = this.fallbackValue;
          else {
            translatedValue = res;
          }
          this.translationStore[translationKey] = translatedValue;
        });
      }
    }
    return {
      ...feature,
      sTranslatedValue: translatedValue,
    };
  }

  translateFacets(facets: Facet[]): EhgTranslatedFacet[] {
    return facets.map((facet) =>
      this.translatedFeatureIDs.includes(`${facet.id}`)
        ? {
            ...facet,
            values: facet.values.map((facetValue) => {
              let translatedKey = '';
              const translationKey = `catalog.shared.feature.${facet.id}.values.${facetValue.key}`;
              if (this.translationStore[translationKey] || this.translationStore[translationKey] === '') {
                translatedKey = this.translationStore[translationKey];
              } else {
                this.translocoService.selectTranslate(translationKey).subscribe((res) => {
                  if (res === translationKey) translatedKey = this.fallbackValue;
                  else {
                    translatedKey = res;
                  }
                  this.translationStore[translationKey] = translatedKey;
                });
              }
              return { ...facetValue, translatedKey } as EhgTranslatedFacetOption;
            }),
          }
        : (facet as EhgTranslatedFacet),
    );
  }
}
