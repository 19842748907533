import { Component, Input, OnInit } from '@angular/core';
import { Article, StockAvailability } from '@lobos/common';
import { AuthService, StockAvailabilityQuery, StockAvailabilityService } from '@lobos/library';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-article-availability',
  templateUrl: './article-availability.component.html',
  styleUrls: ['./article-availability.component.scss'],
})
export class ArticleAvailabilityComponent implements OnInit {
  public authUser$ = this.authService.authUser$;
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  get article(): Article | undefined {
    return this._article;
  }

  @Input()
  defaultStyle = true;

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  public stockAvailability$: Observable<StockAvailability | undefined> | undefined;

  constructor(
    protected stockAvailabilityService: StockAvailabilityService,
    protected stockAvailabilityQuery: StockAvailabilityQuery,
    protected authService: AuthService,
  ) {}

  ngOnInit() {
    this.stockAvailability$ = this.articleChanged$.pipe(
      switchMap(() =>
        iif(
          () => this.stockAvailabilityQuery.hasEntity(this.article?.sArticleID),
          this.stockAvailabilityQuery.selectEntity(this.article?.sArticleID),
          this.stockAvailabilityService
            .get(this.article!.sArticleID)
            .pipe(switchMap(() => this.stockAvailabilityQuery.selectEntity(this.article?.sArticleID))),
        ),
      ),
      untilDestroyed(this),
    );
  }
}
