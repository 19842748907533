import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent {
  @Input()
  public classes: string = '';

  @Input()
  public label: string = '';

  @Input()
  public labelClasses: string = '';

  @Input()
  public required: boolean = false;

  @Input()
  public placeholder: string = '';

  @Input()
  public options: { key: string | number; value: string; disabled?: boolean }[] = [];

  @Input()
  public name: string = '';

  @Input()
  public value: string = '';

  @Input()
  public hasError: boolean = false;

  @Input()
  public errorText: string = '';

  @Input()
  public tooltip: string = '';

  @Input()
  public form: FormGroup | undefined;

  @Input()
  public disabled: boolean = false;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
}
