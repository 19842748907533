import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageResolver } from './services/language/language.resolver';
import { LanguageRootComponent } from './services/language/language-root-component.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LanguageRootComponent,
    children: [],
  },
  {
    path: '',
    loadChildren: () => import('./features/core/core.module').then((m) => m.CoreModule),
  },
  {
    path: ':lang',
    resolve: [LanguageResolver],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./features/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./features/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: 'not-found',
        loadChildren: () => import('./features/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./features/catalog/catalog-features/category/category.module').then((m) => m.CategoryModule),
      },
      {
        path: '',
        loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        path: '**',
        loadChildren: () => import('./features/core/core.module').then((m) => m.CoreModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [165, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
