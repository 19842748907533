<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="navigation$ | async as navigation">
    <div class="navigation-item-menu">
      <button class="navigation-item-menu-back" (click)="backButtonClicked.emit(true)">
        <i class="icon-arrow-left"></i><span>{{ t("core.menu.overview") }}</span>
      </button>

      <div *ngIf="navigation[0] as forms" class="navigation-item-menu-column">
        <div class="navigation-item-menu-title">
          {{ t("core.menu.forms") }}
        </div>

        <div class="navigation-item-menu-content">
          <ng-container *ngFor="let item of forms.navigation">
            <a [routerLink]="['/' | urlPath]" [queryParams]="item.queryParams" class="navigation-item-menu-link">
              <i [ngClass]="item.icon"></i>
              <span>{{ item.title }}</span>
            </a>
          </ng-container>
        </div>
      </div>

      <div *ngIf="navigation[1] as qualities" class="navigation-item-menu-column">
        <div class="navigation-item-menu-title">
          {{ t("core.menu.qualities") }}
        </div>
        <div class="navigation-item-menu-content">
          <a
            *ngFor="let item of qualities.navigation"
            [routerLink]="['/' | urlPath]"
            [queryParams]="item.queryParams"
            class="navigation-item-menu-link"
          >
            <span>{{ item.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
