import { Pipe, PipeTransform } from '@angular/core';
import { P48APIAuthorizationGroup } from '@lobos/common';

@Pipe({
  name: 'roleList',
})
export class RoleListPipe implements PipeTransform {
  public transform(roles: P48APIAuthorizationGroup[] | null): { key: string; value: string }[] {
    const normalizedRoles: { key: string; value: string }[] = [];
    if (!roles?.length) {
      return normalizedRoles;
    }

    // 6: available amount
    const hideStockLevels = [6];

    for (const role of roles) {
      if (role.lngAPIAuthorizationGroupID !== 1 && !hideStockLevels.includes(role.shtStockLevel)) {
        normalizedRoles.push({
          key: role.lngAPIAuthorizationGroupID.toString(),
          value: role.sRoleName,
        });
      }
    }

    return normalizedRoles;
  }
}
