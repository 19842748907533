import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Catch } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { LanguageHelperService } from './language-helper.service';

@Component({
  selector: 'app-language-root-redirect',
  template: '',
})
export class LanguageRootComponent implements OnInit {
  constructor(
    @Optional() @Inject(RESPONSE) private response: Response,
    private languageHelper: LanguageHelperService,
    private router: Router,
    private transloco: TranslocoService,
  ) {}

  @Catch({ label: 'LanguageRootComponent.ngOnInit()' })
  public async ngOnInit(): Promise<void> {
    await this.setLanguageAndRedirect(await this.languageHelper.determineUserLanguage());
  }

  private async setLanguageAndRedirect(language: string): Promise<void> {
    this.transloco.setActiveLang(language);

    // this will only be sent in SSR environment
    if (this.response) {
      this.response.status(302);
      this.response.setHeader('Location', `/${language}`);
      return;
    }

    await this.router.navigate([language]);
  }
}
