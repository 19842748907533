import { Pipe, PipeTransform } from '@angular/core';
import { LanguageQuery, LanguageService } from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Language } from '@lobos/common';

@Pipe({
  name: 'languageHumanReadable',
})
export class LanguageHumanReadablePipe implements PipeTransform {
  constructor(private languageQuery: LanguageQuery<Language>, private languageService: LanguageService<Language>) {}

  public transform(languageId: number): Observable<string> {
    return of(languageId).pipe(
      switchMap((languageId: number) =>
        iif(
          () => this.languageQuery.getHasCache(),
          this.languageQuery.selectEntity(languageId),
          this.languageService.get().pipe(switchMap(() => this.languageQuery.selectEntity(languageId))),
        ),
      ),
      map((language: Language | undefined) => language?.sLanguageName || ''),
    );
  }
}
