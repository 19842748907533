import { Pipe, PipeTransform } from '@angular/core';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { EhgCartItem } from '../../interfaces/ehg-cart-item.interface';

@Pipe({
  name: 'cartItemIsChildProcessed',
})
export class CartItemIsChildProcessedPipe implements PipeTransform {
  transform<T extends EhgCartItem>(items: T[], parent: T): T[] {
    return items ? items.filter((item: T) => ArticleHelperService.isProcessedChildOf(item, parent)) : [];
  }
}
