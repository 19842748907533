import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { _MatAutocompleteBase } from '@angular/material/autocomplete';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {
  @Input()
  public classes: string = '';

  @Input()
  public label: string = '';

  @Input()
  public type: string = 'text';

  @Input()
  public name: string = '';

  @Input()
  public value: string = '';

  @Input()
  public placeholder: string = '';

  @Input()
  public hasButton: boolean = false;

  @Input()
  public buttonIcon: string = '';

  @Input()
  public required: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public hasError: boolean = false;

  @Input()
  public errorText: string = '';

  @Input()
  public form: FormGroup | undefined;

  @Input()
  public isSearchInput: boolean = false;

  @Input()
  public autoComplete!: _MatAutocompleteBase;

  @Input()
  public readonly: boolean = false;

  @Output()
  public buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public focusInEvent: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  @Output()
  public focusOutEvent: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  @Output()
  public clearButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public inputClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  clearInput() {
    this.clearButtonClicked.emit(true);
    this.value = '';
  }
}
