import { Pipe, PipeTransform } from '@angular/core';
import { P48APIRoutingDetail } from '@lobos/common';

@Pipe({
  name: 'isProcessingItemChecked',
})
export class SteelProcessingItemCheckedPipe implements PipeTransform {
  public transform(processingItem: P48APIRoutingDetail, configForm: string[]): boolean {
    return !!configForm.find((item) => item === processingItem.sBO3SteelProcessingArticleID);
  }
}
