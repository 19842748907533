import { FactoryAttestationService } from '@lobos/library';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { EhgFactoryAttestation } from './model/ehg-factory-attestation';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class EhgFactoryAttestationService<T extends EhgFactoryAttestation = EhgFactoryAttestation> extends FactoryAttestationService<T> {
  downloadEhgFactoryAttestationDocument(oFactoryAttestation: T): Observable<any> {
    return this.http
      .post(
        this.factoryAttestationConfig.apiUrl + '/factory-attestation',
        {
          sReference: oFactoryAttestation.sReference,
          sP48AutoCertificateID: oFactoryAttestation.sP48AutoCertificateID,
        },
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        take(1),
        tap((res) => {
          const contentType = res.headers.get('content-type') || '';
          this.ehgSaveFileAs(res.body, `${oFactoryAttestation.sReference}.pdf`, contentType);
        }),
      );
  }

  searchEhgFactoryAttestations(params: any): Observable<T[]> {
    return this.http.get<T[]>(this.factoryAttestationConfig.apiUrl + '/factory-attestation', { params });
  }

  ehgSaveFileAs(file: any, fileName: string, type: string) {
    const blob = new Blob([file], { type });
    saveAs(blob, fileName);
  }
}
