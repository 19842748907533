import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslocoHttpLoaderService } from './services/language/transloco-http-loader.service';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: environment.availableLang,
        defaultLang: environment.defaultLanguage,
        fallbackLang: environment.defaultLanguage,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: { allowEmpty: true },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoaderService },
  ],
})
export class TranslocoRootModule {}
