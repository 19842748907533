import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EhgProduct } from './model/ehg-product';

export interface NavigationFacet {
  key: string;
  doc_count: number;
  isActive: boolean;
  sort: number;
}

export interface CategoryGroups {
  id: string;
  title: string;
  children: CategoryGroups[];
  products: EhgProduct[];
  isOpen: boolean;
  sort: number;
  path: string;
}

export interface FacetedSearchStoreInterface {
  id: string;
  products: CategoryGroups[];
  productTotal: number;
  articleTotal: number;
  facets: any;
  navigation: NavigationFacet[];
  productTreeState?: Record<string, CategoryGroups>;
}

export interface FacetedSearchState extends EntityState<FacetedSearchStoreInterface, string>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'faceted-search' })
export class EhgFacetedSearchStore extends EntityStore<FacetedSearchState> {
  constructor() {
    super();
  }
}
