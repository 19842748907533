<div class="fancy-input" [class.fancy-input--error]="hasError" [class.fancy-input--disabled]="disabled">
  <p class="fancy-input-label">
    {{ label }}
    <ng-container *ngIf="required">*</ng-container>
  </p>
  <div class="fancy-input-container">
    <ng-container *ngIf="form" [formGroup]="form">
      <label for="form-input">
        <span class="fancy-input-container-text">{{ containerText }}</span>
        <input
          id="form-input"
          [maxlength]="maxlength"
          type="text"
          [name]="name"
          [attr.min]="min"
          [placeholder]="placeholder"
          [required]="required"
          [formControlName]="name"
          [class.border-error]="hasError"
          class="fancy-input-container-input"
          tabindex="0"
        />
      </label>
      <span class="fancy-input-container-unit">{{ unit }}</span>
    </ng-container>

    <ng-container *ngIf="!form">
      <label for="non-form-input">
        <span class="fancy-input-container-text">{{ containerText }}</span>
        <input
          id="non-form-input"
          [maxlength]="maxlength"
          *ngIf="!form"
          type="text"
          [name]="name"
          [attr.min]="min"
          [placeholder]="placeholder"
          [required]="required"
          [(ngModel)]="value"
          (ngModelChange)="valueChangeDebouncer.next(value)"
          [class.border-error]="hasError"
          [disabled]="disabled"
          class="fancy-input-container-input"
          tabindex="0"
        />
      </label>
      <span class="fancy-input-container-unit">{{ unit }}</span>
    </ng-container>
  </div>
</div>
<p *ngIf="hasError && errorText" class="text-input-error-text">{{ errorText }}</p>
