import { Query } from '@datorama/akita';
import { NavigationState, NavigationStore } from './navigation.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavigationQuery extends Query<NavigationState> {
  navigation$ = this.select('navigation');

  constructor(protected override store: NavigationStore) {
    super(store);
  }
}
