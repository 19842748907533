import { AfterViewInit, Component } from '@angular/core';
import { AuthService, Catch, CustomerService, FavoriteListQuery, SalesRepService, UrlHelperService } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as $ from 'jquery';
import { Observable, of } from 'rxjs';
import { CmsQuery } from '../../services/cms/cms.query';
import { CmsNavigation } from '../../services/cms/interfaces/cms-navigation.interface';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Customer, FavoriteList, User } from '@lobos/common';
import ClickEvent = JQuery.ClickEvent;
import { filter, first, switchMap, take, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  public searchIsOpen: boolean = false;
  public searchIsFocused: boolean = false;
  public subMenuIsOpen: boolean = false;
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public authUser$: Observable<User | undefined> = this.authService.authUser$;
  cmsNavigation$: Observable<CmsNavigation> = this.cmsQuery.cmsNavigation$;
  cmsNavigation: CmsNavigation | undefined;
  activeFavoriteList$: Observable<FavoriteList | undefined> = this.favoriteListQuery.selectActive();
  protected readonly environment = environment;
  salesRepCustomer$: Observable<Customer | undefined> | undefined;

  constructor(
    private authService: AuthService,
    private cmsQuery: CmsQuery,
    private router: Router,
    private urlHelperService: UrlHelperService,
    private location: Location,
    private favoriteListQuery: FavoriteListQuery,
    private customerService: CustomerService,
    private salesRepService: SalesRepService,
    private translocoService: TranslocoService,
  ) {}

  @Catch({ label: 'HeaderComponent.logout()' })
  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([this.urlHelperService.localizeUrl('')]);
    this.closeSubMenu();
  }

  /**
   * Copy of the design JS-delivery
   * Code is not optimized!
   */
  ngAfterViewInit() {
    this.cmsNavigation$.pipe(untilDestroyed(this)).subscribe((cmsNavigation) => {
      this.cmsNavigation = cmsNavigation;

      if (cmsNavigation) {
        if (typeof window === 'undefined') {
          return;
        }

        this.translocoService
          .selectTranslate('menu.shop', undefined, { scope: 'core' })
          .pipe(
            filter((x) => !!x),
            first(),
            tap((_) => {
              const $body: JQuery = $('body, html');
              const $navigation: JQuery = $('.navigation');
              const $navigationContent: JQuery = $('.navigation-content');
              const $navigationToggler: JQuery = $('.navigation-toggler');
              const $navigationItem: JQuery = $('.navigation-item');
              const $navigationItemTitle: JQuery = $('.navigation-item-title');
              const $navigationItemBack: JQuery = $('.navigation-item-menu-back');
              const noOverFlowClass: string = 'no-overflow';
              const openClass: string = 'navigation--open';
              const itemOpenClass: string = 'navigation-item--open';
              const $document: JQuery<Document> = $(document);

              $navigationToggler.on('click', () => {
                $navigation.toggleClass(openClass);
                $navigationContent.fadeToggle();

                if ($navigation.hasClass(openClass)) {
                  $body.addClass(noOverFlowClass);
                } else {
                  $body.removeClass(noOverFlowClass);
                }
              });

              // Open navigation on item title click, close all other open menus.
              $navigationItemTitle.on('click', (event) => {
                const $target: JQuery = $(event.currentTarget);
                const $item: JQuery = $target.parents('.navigation-item');

                if ($item.hasClass(itemOpenClass)) {
                  $item.removeClass(itemOpenClass);

                  return;
                }

                $navigationItem.removeClass(itemOpenClass);
                $item.addClass(itemOpenClass);
              });

              // Close submenu on mobile when clicked on back button.
              $navigationItemBack.on('click', () => {
                $navigationItem.removeClass(itemOpenClass);
              });

              // Close navigation menu on desktop when clicked outside of menu.
              $document.on('click', (event: ClickEvent<Document>) => {
                if (window.matchMedia('screen and (max-width: 1024px)').matches) {
                  return;
                }

                const $itemTitle: JQuery<Document> = $(event.target).closest('.navigation-item-title');
                const $itemMenu: JQuery<Document> = $(event.target).closest('.navigation-item-menu');

                if (0 < $itemTitle.length || 0 < $itemMenu.length) {
                  return;
                }

                $navigationItem.removeClass(itemOpenClass);
              });
            }),
          )
          .subscribe();
      }
    });

    this.salesRepCustomer$ = this.authService.authUser$.pipe(
      filter((x) => !!x),
      switchMap((user: User | undefined) =>
        user?.sP48SalesRepForUser?.length ? this.customerService.getById(user.lngCustomerID) : of(undefined),
      ),
    );
  }

  toggleSearchBox(searchIsOpen: boolean) {
    this.searchIsOpen = searchIsOpen;
  }

  toggleSearchBoxFocus(searchIsFocused: boolean) {
    this.searchIsFocused = searchIsFocused;
  }

  toggleSubMenu(event: MouseEvent) {
    this.subMenuIsOpen = event.type == 'mouseenter';
  }

  closeSubMenu() {
    this.subMenuIsOpen = false;

    // mobile menu
    // jQuery because it was used like this before
    if (window.matchMedia('screen and (max-width: 1024px)').matches) {
      $('body, html').removeClass('no-overflow');
      $('.navigation').toggleClass('navigation--open');
      $('.navigation-content').fadeToggle();
    }
  }

  navigateToLogin() {
    this.router.navigate([this.urlHelperService.localizeUrl('profile/login')], { queryParams: { redirect: this.location.path() } });
    this.closeSubMenu();
  }

  closeMobileSubMenu() {
    // jQuery because it was used like this before
    $('.navigation-item').removeClass('navigation-item--open');
  }

  backToApp() {
    this.salesRepService
      .resetSalesRepCustomer()
      .pipe(
        take(1),
        tap(() => {
          window.location.href = environment.adAppUrl;
        }),
      )
      .subscribe();
  }
}
