export const ShapeIcons: Record<number, string> = {
  1: 'icon-blech',
  2: 'icon-flach',
  3: 'icon-i-form',
  4: 'icon-profil-rohr',
  5: 'icon-rund',
  6: 'icon-rund-rohr',
  7: 'icon-sechskant',
  8: 'icon-sonderform',
  9: 'icon-t-form',
  10: 'icon-u-form',
  11: 'icon-u-form',
  12: 'icon-vierkant',
  13: 'icon-winkel',
  14: 'icon-winkel-profile',
};
