import { Injectable } from '@angular/core';
import { Article, Price } from '@lobos/common';
import { PriceService } from '@lobos/library';
import { Observable } from 'rxjs';
import { EhgCartItem } from '../../interfaces/ehg-cart-item.interface';

@Injectable({
  providedIn: 'root',
})
export class ArticleHelperService {
  constructor(private priceService: PriceService) {}

  public static itemIsMaster(item: EhgCartItem): boolean {
    if (item.shtFixedItemIDItemGroup) {
      return item.shtFixedItemIDItemGroup === 0 || item.shtFixedItemIDItemGroup === item.shtFixedItemID;
    } else {
      return true;
    }
  }

  public static itemIsChildOf(child: EhgCartItem, master: EhgCartItem): boolean {
    if (child.shtFixedItemIDItemGroup) {
      return (
        child.shtFixedItemIDItemGroup !== 0 &&
        child.shtFixedItemIDItemGroup === master.shtFixedItemIDItemGroup &&
        child.shtFixedItemIDItemGroup !== child.shtFixedItemID
      );
    } else {
      return false;
    }
  }

  public static isProcessedChildOf(child: EhgCartItem, master: EhgCartItem): boolean {
    return child.shtProcessedItem !== 0 && child.shtProcessedItem === master.shtFixedItemID;
  }

  public static isNotProcessedItem(item: EhgCartItem): boolean {
    return item.shtProcessedItem === 0;
  }

  public getPriceFromApi(article: Article): Observable<Price | undefined> {
    return this.priceService.get(
      article.sArticleID,
      article.sQuantityUnitSales,
      'CHF',
      article.decQty > 0 ? article.decQty : 1,
      article.decFactor1,
      article.decFactor2,
      article.decFactor3,
    );
  }
}
