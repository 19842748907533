import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};

@Component({
  selector: 'app-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerInputComponent implements OnInit {
  @Input() public label: string = '';

  @Input() public name: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input() public min?: number | string = undefined;

  @Input() public maxlength: any;

  @Input()
  public value: string = '';

  @Input()
  public valueChangeDebouncing: number = 500;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public valueChangeDebouncer: Subject<string> = new Subject<string>();

  ngOnInit() {
    this.valueChangeDebouncer.pipe(debounceTime(this.valueChangeDebouncing)).subscribe((input: string) => this.valueChange.emit(input));
  }
}
