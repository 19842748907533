import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver implements Resolve<boolean> {
  private readonly response: Response;

  constructor(@Optional() @Inject(RESPONSE) response: Response, private translocoService: TranslocoService, private router: Router) {
    this.response = response;
  }

  public resolve(route: ActivatedRouteSnapshot): any {
    let lang: string = route.params?.['lang'] ?? this.translocoService.getDefaultLang();
    if (!(this.translocoService.getAvailableLangs() as string[]).includes(lang)) {
      this.languageNotFound();
      lang = this.translocoService.getDefaultLang();
      this.router.navigate([lang, 'not-found'], { skipLocationChange: true });
    }
    this.translocoService.setActiveLang(lang);
    return route.params['lang'];
  }

  private languageNotFound(): void {
    // this will only be send in SSR environment
    if (this.response) {
      this.response.status(404);
    }
  }
}
