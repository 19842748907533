<a
  *ngIf="url"
  [routerLink]="url | localizeUrlPath"
  [class.button-primary]="style === 'primary'"
  [class.button-primary--icon]="icon && style === 'primary'"
  [class.button-primary--icon-right]="isIconRight && style === 'primary'"
  [class.button-secondary]="style === 'secondary'"
  [class.button-secondary--icon]="icon && style === 'secondary'"
  [class.button-secondary--icon-right]="isIconRight && style === 'secondary'"
  [class.loading]="loading"
  [class]="classes"
>
  <span *ngIf="text">{{ text }}</span>
  <i *ngIf="icon" [ngClass]="icon"></i>
  <span *ngIf="loading" class="loading-box">
    <span class="loader-inverted"></span>
  </span>
</a>
<button
  *ngIf="!url"
  [type]="type"
  [class.button-primary]="style === 'primary'"
  [class.button-primary--icon]="icon && style === 'primary'"
  [class.button-primary--icon-right]="isIconRight && style === 'primary'"
  [class.button-secondary]="style === 'secondary'"
  [class.button-secondary--icon]="icon && style === 'secondary'"
  [class.button-secondary--icon-right]="isIconRight && style === 'secondary'"
  [class.loading]="loading"
  [class]="classes"
  [disabled]="disabled"
>
  <span *ngIf="text">{{ text }}</span>
  <i *ngIf="icon" [ngClass]="icon"></i>
  <span *ngIf="loading" class="loading-box">
    <span class="loader-inverted"></span>
  </span>
</button>
