<ng-container *transloco="let t">
  <a
    *ngIf="article"
    [routerLink]="['article' | localizeUrlPath, article.sArticleID]"
    (click)="itemClicked.emit($event)"
    class="live-search-item"
  >
    <div *ngIf="article.oProductInfo.length && !imageIsHidden" class="live-search-item-image">
      <app-product-image [product]="article.oProductInfo[0]" (hidden)="imageIsHidden = $event"></app-product-image>
    </div>
    <div class="live-search-item-container">
      <span class="live-search-item-breadcrumb live-search-item-description">
        <ng-container *ngFor="let hierarchy of hierarchies | slice : 1; let last = last">
          {{ hierarchy.sValue }} <i *ngIf="!last" class="icon-angle-right"></i>
        </ng-container>
      </span>
      <p class="live-search-item-title">{{ article.sName }}</p>
      <div class="live-search-item-description">
        <p>
          <span *ngIf="article.sArticleID"
            >{{ t("core.menu.search.shop.article-id") }}: {{ article.sArticleID }}<span class="live-search-item-devider">|</span></span
          >
          <span *ngIf="article.sDescription">
            <ng-container *ngFor="let feature of article.oFeatures; let first = first">
              <ng-container *ngIf="!first"
                >{{ feature.sName }}: {{ feature.sValue }}<span class="live-search-item-devider">|</span></ng-container
              >
            </ng-container>
          </span>
        </p>
      </div>
      <p class="search-item-show-more">{{ t("core.menu.search.search-page.show-more") }}</p>
    </div>
  </a>
</ng-container>
