import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@lobos/common';
import { EhgTranslatedFeature } from '../../interfaces/ehg-translated-feature.interface';
import { CatalogHelperService } from '../../services/catalog/catalog-helper.service';

@Pipe({
  name: 'featureArticleDetail',
})
export class FeatureArticleDetailPipe implements PipeTransform {
  constructor(private catalogHelper: CatalogHelperService) {}

  public transform(features: Feature[]): EhgTranslatedFeature[] {
    return features
      .filter((f) => f.shtRepresentationType !== 0)
      .map((f) => this.catalogHelper.translateFeature(f))
      .sort((a, b) => a.shtRepresentationType - b.shtRepresentationType);
  }
}
