<ng-container *transloco="let t; scope: 'core'">
  <div *ngIf="authUser$ | async as authUser">
    <ng-container *ngIf="stockAvailability$ | async as stockAvailability">
      <ng-container *ngIf="defaultStyle; else simpleStyle">
        <div *ngIf="authUser.oP48APIAuthorizationGroup.shtStockLevel > 1">
          <div>
            <ng-container *ngIf="stockAvailability.decAvailable && stockAvailability.decAvailable > 0; else showIsAvailable">
              <div class="article-availability">
                <div
                  class="profile-offer-detail-table-item-column-in-stock"
                  [class.profile-offer-detail-table-item-column-in-stock--yes]="stockAvailability.shtAvailable > 0"
                  [class.profile-offer-detail-table-item-column-in-stock--no]="stockAvailability.shtAvailable <= 0"
                >
                  {{
                    stockAvailability.shtAvailable <= 0
                      ? t("core.article-availability.in-review")
                      : t("core.article-availability.available")
                  }}
                </div>
                <span *ngIf="stockAvailability.decAvailable && stockAvailability.decAvailable > 0">{{
                  stockAvailability.decAvailable.toLocaleString("de-AT") + " " + stockAvailability.sQuantityUnit
                }}</span>
              </div>
            </ng-container>
            <ng-template #showIsAvailable>
              <div class="article-availability">
                <div
                  class="profile-offer-detail-table-item-column-in-stock"
                  [class.profile-offer-detail-table-item-column-in-stock--yes]="stockAvailability.shtAvailable > 0"
                  [class.profile-offer-detail-table-item-column-in-stock--no]="stockAvailability.shtAvailable <= 0"
                >
                  {{
                    stockAvailability.shtAvailable <= 0
                      ? t("core.article-availability.in-review")
                      : t("core.article-availability.available")
                  }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div *ngIf="authUser.oP48APIAuthorizationGroup.shtStockLevel === 1">
          <div class="article-availability">
            <div class="profile-offer-detail-table-item-column-in-stock profile-offer-detail-table-item-column-in-stock--yes">
              {{ t("core.article-availability.available") }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #simpleStyle>
        <ng-container *ngIf="stockAvailability.decAvailable && stockAvailability.decAvailable > 0">{{
          t("core.article-availability.available-amount", {
            amount: stockAvailability.decAvailable.toLocaleString("de-AT"),
            unit: stockAvailability.sQuantityUnit
          })
        }}</ng-container>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
