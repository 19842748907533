import { HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library';
import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CmsStore } from '../../../services/cms/cms.store';
import { CmsService } from '../../../services/cms/cms.service';
import { first } from 'rxjs/operators';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCmsStoresHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private cmsStore: CmsStore, private cmsService: CmsService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override priority(): number {
    return HookPriority.LOW;
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    this.cmsStore.reset();

    forkJoin([this.cmsService.getCmsFooter(), this.cmsService.getCmsNavigation()]).pipe(first(), untilDestroyed(this)).subscribe();

    return super.after(event, result);
  }
}
