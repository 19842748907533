import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { BreadcrumbsService } from '@lobos/library';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from '@lobos/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  public isBackOptionShown: boolean = false;

  public crumbs$: Observable<BreadcrumbItem[]> = this.breadcrumbsService.breadcrumbs$;

  constructor(private breadcrumbsService: BreadcrumbsService, private location: Location) {}

  back() {
    this.location.back();
  }
}
