import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Navigation {
  type: string;
  title: string;
  navigation: NavigationItem[];
}

export interface NavigationItem {
  title: string;
  link: string;
  queryParams: { [key: string]: string };
  icon: string;
}

export interface NavigationState {
  navigation: Navigation[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'navigation', resettable: true })
export class NavigationStore extends Store<NavigationState> {
  constructor() {
    super({ navigation: [] });
  }
}
