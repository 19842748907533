import { QueryEntity } from '@datorama/akita';
import { EhgFacetedSearchStore, FacetedSearchState } from './ehg-faceted-search.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EhgFacetedSearchQuery extends QueryEntity<FacetedSearchState> {
  constructor(protected override store: EhgFacetedSearchStore) {
    super(store);
  }
}
