import { Injectable } from '@angular/core';
import { SteelCartItem, User, CartHeader } from '@lobos/common';
import {
  AuthChangedHook,
  CartService,
  ErrorService,
  HookEvent,
  HookHandlerAbstract,
  SteelCreateCartItemInterface,
  switchTap,
} from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

type type = AuthChangedHook;

/**
 * When the user logs in and has no active cart (`lngActiveCartID`),
 * activate the most recent cart. If there is no cart at all, create a new, empty cart.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizedActivateCartHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(
    private errorService: ErrorService,
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(
      switchTap<User>((user?: User) =>
        iif(
          // no user, or user `lngActiveCartID` is already set
          () => !user || !!user.lngActiveCartID,
          // true
          of(user),
          // false
          this.cartService.activateRecentOrCreateNew(user!).pipe(
            catchError((error: Error) =>
              of(
                this.errorService.add({
                  label: 'AuthorizedActivateCart.after()',
                  ...error,
                  error,
                }),
              ),
            ),
            first(),
          ),
        ),
      ),
    );
  }
}
