import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-amount-counter',
  templateUrl: 'amount-counter.component.html',
  styleUrls: ['amount-counter.component.scss'],
})
export class AmountCounterComponent implements OnInit, OnChanges {
  @Input()
  form: FormGroup | undefined;

  @Input()
  controlName: string = '';

  @Input()
  showLabel: boolean = false;

  @Input()
  quantity: number = 1;

  @Output()
  quantityChanged = new EventEmitter<number>();

  quantityChanged$: Subject<number> = new Subject<number>();

  public amountForm: FormGroup<{ quantity: FormControl<number> }> = new FormGroup({
    quantity: new FormControl(1, { validators: [Validators.required, Validators.min(1), Validators.pattern('[0-9]+')], nonNullable: true }),
  });

  constructor() {
    this.quantityChanged$
      .pipe(
        debounceTime(200),
        tap((newQuantity) => this.quantityChange(newQuantity)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.form?.get(this.controlName)?.value) {
      this.quantity = this.form?.get(this.controlName)?.value;
      this.form
        ?.get(this.controlName)
        ?.valueChanges.pipe(
          debounceTime(100),
          distinctUntilChanged(),
          tap((q) => {
            this.quantity = q;
            this.quantityChange(q);
          }),
        )
        .subscribe();
    }

    this.amountForm.controls.quantity.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          if (this.amountForm.valid) {
            this.quantity = this.amountForm.controls.quantity.value;
            this.quantityChange(this.quantity);
          } else {
            this.form?.controls[this.controlName].setErrors({ incorrect: true });
          }
        }),
      )
      .subscribe();
  }

  ngOnChanges() {
    this.amountForm.controls.quantity.patchValue(this.quantity, { emitEvent: false });
  }

  quantityMinus() {
    if (this.quantity > 1) {
      this.quantity -= 1;
    }
    this.quantityChanged$.next(this.quantity);
  }

  quantityPlus() {
    this.quantity += 1;
    this.quantityChanged$.next(this.quantity);
  }

  quantityChange(newQuantity: number) {
    this.amountForm.controls.quantity.patchValue(newQuantity, { emitEvent: false });

    const formControl = this.form?.get(this.controlName);
    if (formControl) {
      formControl.patchValue(newQuantity);
      this.form?.updateValueAndValidity({ emitEvent: true });
    }
    this.quantityChanged.emit(newQuantity);
  }
}
