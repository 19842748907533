import { CmsContentState, CmsStore } from './cms.store';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { CmsFooter } from './interfaces/cms-footer.interface';
import { Observable } from 'rxjs';
import { CmsNavigation } from './interfaces/cms-navigation.interface';

@Injectable({ providedIn: 'root' })
export class CmsQuery extends QueryEntity<CmsContentState> {
  cmsFooter$: Observable<CmsFooter> = this.select('cmsFooter');
  cmsNavigation$: Observable<CmsNavigation> = this.select('cmsNavigation');

  constructor(protected override store: CmsStore) {
    super(store);
  }
}
