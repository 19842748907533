import { Component, Input } from '@angular/core';
import { ID } from '@datorama/akita';
import { FavoriteList, SteelArticle, SteelFavoriteItemModel } from '@lobos/common';
import { createFavoriteItem, FavoriteItemQuery, FavoriteListQuery, FavoriteService } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-is-favorite',
  templateUrl: './is-favorite.component.html',
  styleUrls: ['./is-favorite.component.scss'],
})
export class IsFavoriteComponent {
  @Input() article: SteelArticle | undefined;

  public isFavorite$: Observable<boolean> = this.favoriteListQuery.selectActiveId().pipe(
    map((id: ID | undefined | null) => id as ID),
    switchMap((id) => this.favoriteItemQuery.selectFavoriteItems(id)),
    map((items: SteelFavoriteItemModel[]) => {
      return !!items.find((item) => item.oArticle?.sArticleID === this.article?.sArticleID);
    }),
  );

  constructor(
    private favoriteItemQuery: FavoriteItemQuery<SteelFavoriteItemModel>,
    private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>,
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
  ) {}

  public toggleFavorite(currentState: boolean | null) {
    if (!currentState) {
      this.addArticleToFavoriteList();
    } else {
      this.removeArticleFromFavoriteList();
    }
  }

  private addArticleToFavoriteList() {
    const activeFavoriteListId: ID | undefined | null = this.favoriteListQuery.getActiveId();
    const newFavoriteItem: SteelFavoriteItemModel = createFavoriteItem<SteelFavoriteItemModel>({
      gListID: activeFavoriteListId as string,
      oArticle: this.article,
      decQuantity: 1,
    });

    this.favoriteService.createFavoriteItem(newFavoriteItem, activeFavoriteListId!).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  private removeArticleFromFavoriteList() {
    this.favoriteItemQuery
      .selectEntity(
        (item: SteelFavoriteItemModel) =>
          item.oArticle?.sArticleID === this.article!.sArticleID && item.gListID === this.favoriteListQuery.getActiveId(),
      )
      .pipe(
        first(),
        filter((favoriteItem: SteelFavoriteItemModel | undefined) => !!favoriteItem),
        switchMap((favoriteItem: SteelFavoriteItemModel | undefined) => this.favoriteService.deleteFavoriteItem(favoriteItem!)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
