import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MultiIndexSearchResponse } from '@lobos/common';
import { CATALOG_CONFIG, CatalogConfig, SearchStore } from '@lobos/library';

@Injectable({ providedIn: 'root' })
export class EhgSearchService {
  /**
   * TODO remove or adapt this class as soon as SearchService in Lobos-Library gets refactored and parameter intPageSize has been added (ticket #154 in "Shop-Weiterentwicklung")
   *
   * Creates an instance of search service.
   */
  constructor(protected store: SearchStore, @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig, protected http: HttpClient) {}

  search(searchTerm: string, size: number = 4): Observable<MultiIndexSearchResponse> {
    this.store.setLoading(true);
    return this.http.get<MultiIndexSearchResponse>(this.catalogConfig.apiUrl + '/search?q=' + searchTerm + '&intPageSize=' + size).pipe(
      tap((response: MultiIndexSearchResponse) => {
        this.store.update({
          articles: response.articles,
          totalArticles: response.articleTotal,
          categories: response.categories,
          searchTerm: searchTerm,
          articleHighlights: response.articleHighlights,
          categoryHighlights: response.categoryHighlights,
        });
        this.store.setLoading(false);
      }),
      catchError((error: Error) => {
        this.store.setLoading(false);
        return throwError(() => error);
      }),
    );
  }

  resetStore() {
    this.store.reset();
  }
}
