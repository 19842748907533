import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

const blacklist = ['/api/users', '/api/signup'];

@Injectable()
export class HandleErrorsInterceptor implements HttpInterceptor {
  constructor(public toasterService: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get path from request url
    let requestPath = req.url;
    if (requestPath.startsWith('http')) {
      requestPath = req.url.substring(req.url.indexOf('/', req.url.indexOf('//') + 2));
    }

    return next.handle(req).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status !== 401 && !blacklist.includes(requestPath)) {
            try {
              this.toasterService.error(
                err.error.error ? (err.error.error.text ? err.error.error.text : err.error.error) : err.error.text,
                '',
                { positionClass: 'toast-top-right' },
              );
            } catch (e) {
              this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
            }
          }
        },
      }),
    );
  }
}
