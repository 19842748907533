import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { BreakpointConstant } from '../../constants/breakpoint.constant';

@UntilDestroy()
@Component({
  selector: 'app-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
  viewProviders: [MatExpansionPanel],
})
export class CollapsableComponent implements OnInit {
  @Input()
  public isOpen: boolean = true;

  @Input()
  public hideToggle: boolean = true;

  /**
   * If set to true, the collapsable will force open on desktop breakpoints
   */
  @Input()
  public respectBreakpoint: boolean = false;

  @Output()
  public isOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>(this.isOpen);

  public forceOpen: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    if (!this.respectBreakpoint) {
      return;
    }

    this.breakpointObserver
      .observe([`(min-width: ${BreakpointConstant.TABLET}px)`])
      .pipe(
        map((result: BreakpointState) => result.matches),
        untilDestroyed(this),
      )
      .subscribe((forceOpen: boolean) => (this.forceOpen = forceOpen));
  }

  public toggle(open: boolean): void {
    this.isOpenChanged.emit(open);
  }
}
