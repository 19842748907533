import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { AuthModule, FilterService, FilterStore, LocalizeUrlPathModule } from '@lobos/library';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { BreadcrumbOutletComponent } from './breadcrumb/breadcrumb-outlet.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonIconComponent } from './button-icon/button-icon.component';
import { ButtonTextComponent } from './button-text/button-text.component';
import { ButtonComponent } from './button/button.component';
import { CollapsableComponent } from './collapsable/collapsable.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IsFavoriteComponent } from './is-favorite/is-favorite.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { NavigationCartComponent } from './navigation-cart/navigation-cart.component';
import { NavigationItemMenuComponent } from './navigation-item-menu/navigation-item-menu.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { ShellComponent } from './shell/shell.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextInputComponent } from './text-input/text-input.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ArticleAvailabilityComponent } from './article-availability/article-availability.component';
import { FancyTextInputComponent } from './fancy-text-input/fancy-text-input.component';
import { DatepickerInputComponent } from './datepicker-input/datepicker-input.component';
import { AmountCounterComponent } from './amount-counter/amount-counter.component';
import { ProductImageComponent } from './product-image/product-image.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { NgFirstOrDefaultPipeModule, NgWherePipeModule } from 'angular-pipes';
import { PipesModule } from '../pipes/pipes.module';
import { TableScrollComponent } from './table-scroll/table-scroll.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ArticleListItemComponent } from './article-list-item/article-list-item.component';

const components: any[] = [
  ShellComponent,
  FooterComponent,
  HeaderComponent,
  ButtonTextComponent,
  NavigationCartComponent,
  SelectBoxComponent,
  TextInputComponent,
  TextAreaComponent,
  NavigationItemMenuComponent,
  SearchBoxComponent,
  LanguageSelectComponent,
  BreadcrumbComponent,
  SimpleHeaderComponent,
  ButtonComponent,
  BreadcrumbOutletComponent,
  IsFavoriteComponent,
  ButtonIconComponent,
  CollapsableComponent,
  PaginationComponent,
  ArticleAvailabilityComponent,
  FancyTextInputComponent,
  DatepickerInputComponent,
  AmountCounterComponent,
  ProductImageComponent,
  ConfirmationDialogComponent,
  TableScrollComponent,
];

@NgModule({
  declarations: [components, ArticleListItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    LocalizeUrlPathModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDialogModule,
    MatTabsModule,
    NgWherePipeModule,
    PipesModule,
    NgFirstOrDefaultPipeModule,
    MatAutocompleteModule,
    AuthModule,
  ],
  exports: [components, ArticleListItemComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
    FilterStore,
    FilterService,
  ],
})
export class ComponentsModule {}
