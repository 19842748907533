<div class="text-area" [ngClass]="classes" [class.text-input--error]="hasError">
  <p *ngIf="label" class="text-area-label">{{ label }} <ng-container *ngIf="required">*</ng-container></p>

  <ng-container *ngIf="form" [formGroup]="form">
    <textarea [name]="name" [formControlName]="name" [rows]="rows" [required]="required" [placeholder]="placeholder"></textarea>
  </ng-container>

  <ng-container *ngIf="!form">
    <textarea
      [name]="name"
      [rows]="rows"
      [required]="required"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit(value)"
    ></textarea>
  </ng-container>

  <p *ngIf="hasError && errorText" class="text-input-error-text">{{ errorText }}</p>
</div>
