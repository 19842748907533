import { Pipe, PipeTransform } from '@angular/core';
import { ShapeIcons } from '../../services/catalog/shape-icons';

@Pipe({
  name: 'shapeIconClass',
})
export class ShapeIconClassPipe implements PipeTransform {
  public transform(id: number): string {
    return ShapeIcons[id];
  }
}
