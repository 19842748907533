<ng-container *transloco="let t; scope: 'shared'">
  <pagination-template class="pagination" #p="paginationApi" [id]="$any(config.id)" (pageChange)="pageChangeHandler($event)">
    <nav aria-label="Pagination">
      <div class="pagination-container">
        <a class="pagination-arrow pagination-arrow-left" [class.disabled]="p.isFirstPage()" (click)="p.previous()">
          <i class="icon-arrow-left"></i>
        </a>
        <p class="pagination-description">
          <span>{{ 1 + config.itemsPerPage * (p.getCurrent() - 1) }}</span>
          {{ t("shared.pagination.to") }}
          <span>{{
            config.itemsPerPage * p.getCurrent() > p.getTotalItems() ? p.getTotalItems() : config.itemsPerPage * p.getCurrent()
          }}</span>
          {{ t("shared.pagination.of") }}
          <span>{{ p.getTotalItems() }}</span>
          {{ t("shared.pagination.results") }}
        </p>
        <a class="pagination-arrow pagination-arrow-right" [class.disabled]="p.isLastPage()" (click)="p.next()">
          <i class="icon-arrow-right"></i>
        </a>
      </div>
    </nav>
  </pagination-template>
</ng-container>
