import { AfterViewInit, Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-table-scroll',
  templateUrl: './table-scroll.component.html',
  styleUrls: ['./table-scroll.component.scss'],
})
export class TableScrollComponent implements AfterViewInit {
  @Input() fullLength: boolean = false;

  @HostListener('window:resize', ['$event']) // for window scroll events
  onResize() {
    const scrollBtns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.js-table-scroll');
    if (!scrollBtns.length) {
      return;
    }

    scrollBtns.forEach((scrollBtn: HTMLElement) => this.hideScrollRightButton(scrollBtn));
  }

  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    const scrollBtns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.js-table-scroll');
    if (!scrollBtns.length) {
      return;
    }

    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => scrollBtns.forEach((scrollBtn: HTMLElement) => this.hideScrollRightButton(scrollBtn)), 500);
  }

  scrollHandler(e: Event) {
    const scrollBtn = (e.target as HTMLElement)!;
    this.hideScrollRightButton(scrollBtn);

    const table = scrollBtn.closest('.js-table-wrap')?.querySelector('.js-table');
    const tableScrollPos = table!.scrollLeft;
    const maxScrollPos = table!.scrollWidth - table!.clientWidth;
    let newScrollPos = tableScrollPos + 200;

    if (scrollBtn.classList.contains('js-table-scroll--right')) {
      if (scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--left')!.classList.contains('hidden')) {
        scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--left')!.classList.remove('hidden');
      }
    } else {
      newScrollPos = tableScrollPos - 200;
      if (scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.contains('hidden')) {
        scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.remove('hidden');
      }
    }

    if (newScrollPos >= maxScrollPos) {
      scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.add('hidden');
    }

    if (newScrollPos <= 0) {
      scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--left')!.classList.add('hidden');
    }

    table!.scrollTo({
      top: 0,
      left: newScrollPos,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      behaviour: 'smooth',
    });
  }

  hideScrollRightButton(scrollBtn: HTMLElement) {
    const tableWidth = scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table')!.clientWidth;
    const scrollWidth = scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table')!.scrollWidth;

    if (tableWidth == scrollWidth && scrollBtn.classList.contains('js-table-scroll--right')) {
      scrollBtn.classList.add('hidden');
    } else {
      if (scrollBtn.classList.contains('js-table-scroll--right')) {
        scrollBtn.classList.remove('hidden');
      }
    }
  }
}
