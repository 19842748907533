import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent {
  @Input()
  public classes: string = '';

  @Input()
  public label: string = '';

  @Input()
  public name: string = '';

  @Input()
  public value: string = '';

  @Input()
  public placeholder: string = '';

  @Input()
  public required: boolean = false;

  @Input()
  public rows: number = 1;

  @Input()
  public hasError: boolean = false;

  @Input()
  public errorText: string = '';

  @Input()
  public form: FormGroup | undefined;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
}
