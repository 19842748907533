import { Pipe, PipeTransform } from '@angular/core';
import { EhgProduct } from '../../services/catalog/model/ehg-product';
import { MaterialNumberHelperService } from '../../services/catalog/material-number.helper.service';

@Pipe({
  name: 'getMaterialNumber',
})
export class GetMaterialNumberPipe implements PipeTransform {
  constructor(private materialNumberHelper: MaterialNumberHelperService) {}

  public transform(products: EhgProduct | EhgProduct[]): string[] {
    return this.materialNumberHelper.convertToStringArray(products);
  }
}
