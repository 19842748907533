import { Injectable } from '@angular/core';
import { CatalogStore, HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { NavigationService } from '../../../services/catalog/navigation/navigation.service';
import { NavigationStore } from '../../../services/catalog/navigation/navigation.store';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCatalogMenuHandler extends HookHandlerAbstract<type, Observable<boolean>> {
  constructor(private catalogStore: CatalogStore, private navigationService: NavigationService, private navigationStore: NavigationStore) {
    super();
  }

  override priority(): number {
    return HookPriority.HIGH;
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<boolean>>, result: Observable<boolean>): Observable<boolean> {
    this.catalogStore.reset();
    this.navigationStore.reset();
    forkJoin([this.navigationService.getNavigation()]).pipe(first(), untilDestroyed(this)).subscribe();

    return super.after(event, result);
  }
}
