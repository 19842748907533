import { Injectable } from '@angular/core';
import { AuthChangedHook, HookEvent, HookHandlerAbstract, HookPriority, SentryService } from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '@lobos/common';

type type = AuthChangedHook;

/**
 * This hook updates Sentry, whenever the user changes.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationChangedUpdateSentryHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(private sentry: SentryService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override priority(): number {
    return HookPriority.HIGHEST;
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(tap((user: User) => this.sentry.updateUser(user)));
  }
}
