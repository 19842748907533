import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CmsStore } from './cms.store';
import { CmsFooter } from './interfaces/cms-footer.interface';
import { tap } from 'rxjs/operators';
import { CmsNavigation } from './interfaces/cms-navigation.interface';
import { CmsSearch } from './interfaces/cms-search.interface';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('ehg:staging'),
    }),
  };

  constructor(private http: HttpClient, private store: CmsStore, private transloco: TranslocoService) {}

  getCmsFooter() {
    return this.http
      .get<CmsFooter>('/cms/' + this.transloco.getActiveLang() + '/api/footer', this.httpOptions)
      .pipe(tap((footer) => this.store.update({ cmsFooter: footer })));
  }

  getCmsNavigation() {
    return this.http
      .get<CmsNavigation>('/cms/' + this.transloco.getActiveLang() + '/api/navigation', this.httpOptions)
      .pipe(tap((navigation) => this.store.update({ cmsNavigation: navigation })));
  }

  searchCms(searchTerm: string) {
    return this.http
      .get<CmsSearch>('/cms/' + this.transloco.getActiveLang() + '/api/search/' + searchTerm, this.httpOptions)
      .pipe(tap((search) => this.store.update({ cmsSearch: search })));
  }
}
