<mat-expansion-panel
  [class.respect-breakpoint]="respectBreakpoint && forceOpen"
  [hideToggle]="hideToggle"
  [expanded]="isOpen || (respectBreakpoint && forceOpen)"
  (opened)="toggle(true)"
  (closed)="toggle(false)"
>
  <mat-expansion-panel-header>
    <ng-content mat-button select="[collapsable-title]"></ng-content>
  </mat-expansion-panel-header>
  <ng-content select="[collapsable-content]"></ng-content>
</mat-expansion-panel>
