import { Pipe, PipeTransform } from '@angular/core';
import { ShippingAddress } from '@lobos/common';
import { AddressHelperService } from '../../services/catalog/address-helper.service';

@Pipe({
  name: 'shippingAddressList',
})
export class ShippingAddressListPipe implements PipeTransform {
  constructor(private addressHelperService: AddressHelperService) {}

  public transform(addresses: ShippingAddress[] | null): { key: number; value: string }[] {
    const normalizedAddresses: { key: number; value: string }[] = [];
    if (!addresses?.length) {
      return normalizedAddresses;
    }

    for (const address of addresses) {
      normalizedAddresses.push({
        key: +address.lngAddressID,
        value: this.addressHelperService.summary(address),
      });
    }

    // this cuts away the trailing space and coma of the string 'Gleich wie Rechnungsadresse, '.
    if (normalizedAddresses.find((address) => address.key === -1)) {
      normalizedAddresses[normalizedAddresses.findIndex((address) => address.key === -1)].value = normalizedAddresses
        .find((address) => address.key === -1)!
        .value.split(',')[0];
    }

    return normalizedAddresses;
  }
}
