import { Injectable } from '@angular/core';
import { EhgProduct } from './model/ehg-product';
import { isArray } from 'lodash';

@Injectable({ providedIn: 'root' })
export class MaterialNumberHelperService {
  public convertToStringArray(products: EhgProduct | EhgProduct[]): string[] {
    const materialNumber: string[] = [];
    if (isArray(products)) {
      products.forEach((item) => {
        if (item.sPL1MaterialNumbers && item.sPL1MaterialNumbers.length > 0) {
          materialNumber.push(...item.sPL1MaterialNumbers);
        }
      });
    } else {
      if (products.sPL1MaterialNumbers && products.sPL1MaterialNumbers.length > 0) {
        materialNumber.push(...products.sPL1MaterialNumbers);
      }
    }
    return [...new Set(materialNumber)].sort((a, b) => {
      if (a.match(/[a-zA-Z]/g)) {
        return 1000;
      }
      if (b.match(/[a-zA-Z]/g)) {
        return -1000;
      }
      return +a - +b;
    });
  }
}
