<a *ngIf="url" class="button-icon" [class]="classes" [routerLink]="url | localizeUrlPath" [queryParams]="queryParams">
  <i *ngIf="icon && !loading" [class]="icon"></i>
  <i *ngIf="loading" class="loading-spinner"></i>
  <span *ngIf="tooltip" class="button-icon-tooltip">{{ tooltip }}</span>
</a>

<button *ngIf="!url" type="button" class="button-icon" [class]="classes" [disabled]="disabled || loading" (click)="buttonClicked.emit()">
  <i *ngIf="icon && !loading" [class]="icon"></i>
  <i *ngIf="loading" class="loading-spinner"></i>
  <span *ngIf="tooltip" class="button-icon-tooltip">{{ tooltip }}</span>
</button>
