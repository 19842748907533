import { Pipe, PipeTransform } from '@angular/core';
import { SalesRep } from '@lobos/common';

@Pipe({
  name: 'salesRepFieldList',
})
export class SalesRepFieldListPipe implements PipeTransform {
  transform(salesRep: SalesRep | undefined): any {
    if (salesRep) {
      let fieldList = '';
      if (salesRep.sSalesRepName) fieldList = salesRep.sSalesRepName;
      if (salesRep.sEmail) fieldList += fieldList.length > 0 ? `<br />${salesRep.sEmail}` : salesRep.sEmail;
      if (salesRep.sPhone) fieldList += fieldList.length > 0 ? `<br />${salesRep.sPhone}` : salesRep.sPhone;
      return fieldList;
    }
    return '';
  }
}
