<ng-container *transloco="let t; scope: 'favorite'">
  <button
    *ngIf="{ isFavorite: (isFavorite$ | async) } as context"
    (click)="toggleFavorite(context.isFavorite)"
    class="header-favorite-button"
  >
    <i class="header-favorite-button-icon" [class.icon-star]="!context?.isFavorite" [class.icon-trash]="context.isFavorite"></i>
    <span *ngIf="context.isFavorite" class="header-favorite-button-title">{{ t("favorite.header.remove") }}</span>
    <span *ngIf="!context.isFavorite" class="header-favorite-button-title">{{ t("favorite.header.add") }}</span>
  </button>
</ng-container>
