import { EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { CmsFooter } from './interfaces/cms-footer.interface';
import { CmsNavigation } from './interfaces/cms-navigation.interface';
import { CmsSearch } from './interfaces/cms-search.interface';

export interface CmsContentState {
  cmsFooter: CmsFooter;
  cmsNavigation: CmsNavigation;
  cmsSearch: CmsSearch;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cms-store' })
export class CmsStore extends EntityStore<CmsContentState> {
  constructor() {
    super({
      cmsFooter: undefined,
      cmsNavigation: undefined,
    });
  }
}
