<ng-container *transloco="let t; scope: 'core'">
  <div class="breadcrumb" *ngIf="crumbs$ | async as crumbs">
    <div *ngIf="isBackOptionShown" class="breadcrumb-front">
      <a (click)="back()" class="breadcrumb-back">
        <i class="breadcrumb-arrow icon-arrow-left"></i>
        {{ t("core.breadcrumb.back") }}
      </a>
      <span class="breadcrumb-divider"></span>
    </div>
    <div class="breadcrumb-crumbs">
      <div class="crumb" *ngFor="let crumb of crumbs">
        <a [routerLink]="crumb.url" class="crumb-title">{{ crumb.label }}</a>
        <i class="crumb-split icon-angle-right"></i>
      </div>
    </div>
  </div>
</ng-container>
